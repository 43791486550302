import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import Seo from "../components/seo"

export default function Contact() {
  return (

  <Layout isIndex={true} bgImg="home.jpg" title="Contact Us">
      <Seo title="Contact Us" />

      <div class="max-w-xl mx-auto bg-white shadow-md rounded-md overflow-hidden mt-8">
        <div class="text-center p-4">
          <h1 class="text-4xl font-bold">Contact Us</h1>
          <p class="text-2xl text-gray-700">The Platinum Services Dmc Italy and Events</p>
        </div>
        <div class="pl-12 text-justify text-gray-500 pb-4">
          <p className="text-gray-600">Operations office</p>
          <p>Via degli Apuli 54 00185 Roma - Italy</p>
          <p>Email address: <a className="text-gray-500 hover:text-gray-400" href="mailto:info@theplatinumservices.it">info@theplatinumservices.it</a></p>
          <p>Tel 06 4460449 Mob Whatsapp : +393339470120</p>
          <p className="text-sm">Affiliate to : Evintra, Micebook, Connections</p>
        </div>
      </div>

    </Layout>
  )
}
